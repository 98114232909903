.row-dolphinpacific {
  display: flex;
  flex-direction: row;
  padding: 0 4px;
  justify-content: center;
  text-align: center;
}

.column-dolphinpacific {
  flex: 25%;
  max-width: 25%;
  padding: 50px 40px;
  justify-content: center;
}

.column-dolphinpacific img {
  margin-top: 10px;
  vertical-align: middle;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .column-dolphinpacific {
    flex: 100%;
    max-width: 100%;
  }
}

.sidebar-dolphinpacific {
  flex: 50%;
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 250px; /* Set the width of the sidebar */
  position: absolute;
  margin-left: 50px;
}

/* Sidebar links */
.sidebar-dolphinpacific h1 {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}
/* Sidebar links */
.sidebar-dolphinpacific p {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}
/* Sidebar links */
.sidebar-dolphinpacific ul {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

@media screen and (max-width: 400px) {
  .sidebar-dolphinpacific a {
    text-align: center;
    float: none;
  }
}

.main-text-area-dolphinpacific {
  margin-left: 200px;
  padding: 0 350px;
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .main-text-area-dolphinpacific {
    flex: 100%;
    padding: 0 30px;
    margin-left: 0px;
    margin: 10px;
  }
  .sidebar-dolphinpacific {
    flex: 100%;
    height: auto;
    position: relative;
  }
  .sidebar-dolphinpacific a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
  .row-dolphinpacific {
    flex-direction: column;
    margin: 0 10px;
  }
  .column-dolphinpacific {
    flex: 1000%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .main-text-area-dolphinpacific {
    flex: 100%;
    padding: 0px 30px;
    
    padding-bottom: 0px;
    margin-left: 0px;
    margin: 40px;
  }
  .row-dolphinpacific {
    flex-direction: column;
    margin: 0 0px;
  }
  .column-dolphinpacific {
    flex: 100%;
    max-width: 100%;
  }
  .sidebar-dolphinpacific {
    flex: 100%;
    height: auto;
    position: relative;
    width: 70%;
  }
}

.container-dolphinpacific {
  margin-left: 160px;
}
