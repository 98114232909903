* {
  box-sizing: border-box;
}

.card-projects-main {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

img-projects-main {
  border-radius: 5px 5px 0 0;
}

.column-projects-main {
  float: left;
  width: 33%;
  padding: 10px 33px;
}

.row-projects-main:after {
  content: "";
  display: table;
  clear: both;
}

.sub-text-projects-main {
  font-size: 1.3rem;
}

.row-projects-main {
  margin: 0 -5px;
}

.row-projects-main h3 {
  padding: 0 10px;
}
.row-projects-main p {
  padding: 10px 10px;
}
.row-projects-main h2 {
  text-align: center;
}

.sub-text-projects-main {
  text-align: center;
}

.grid-container-projects-main {
  align-content: center;
  position: relative;
  padding-bottom: 100px;
}

.nav-button-projects-main {
  font-weight: 500;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  background-color: #6366f1;

  color: #ffffff;

  border-radius: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.5rem;

  width: 10rem;
  height: 2.5rem;
}

.button-container-projects-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.link-projects-main {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 600px) {
  .column-projects-main {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}
