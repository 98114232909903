.hero-container {
  height: 600px;
  padding: 20px;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: normal;
  gap: 40px;
  margin-top: 100px;
  margin-bottom: 150px;
}

.hero-container img {
  width: 40%;
  height: 700px;
}

.hero-text {
  width: 30%;
  margin-top: 50px;
}
.hero-text p {
  font-size: 1.3rem;
  margin-bottom: 12px;
}

.hero-text h1 {
  font-size: 3.3em;
  margin-bottom: 12px;
}

.hero-container button {
  font-weight: 500;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  background-color: #ffffff;
  /* transition: background-color 0.3s; */
  color: #ffffff;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);  */
  border-radius: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.5rem;
  /* transition-duration: 0.3s; */
  width: 10rem;
  height: 2.5rem;
  color: black;
  text-transform: uppercase;
}

@media (max-width: 848px) {
  .hero-container {
    margin-top: 10px;
    flex-direction: column;
    margin-bottom: 100px;
  }

  .hero-text {
    width: 100%;
    text-align: center;
    margin-top: 0px;
  }
  .hero-text h1 {
    font-size: 3rem;
  }

  .hero-container img {
    width: 100%;
    height: 700px;
  }
}

@media (max-width: 768px) {
  .hero-container button {
    margin-bottom: 130px;
    margin-top: 30px;
  }
  .hero-container img {
    height: 400px;
    margin-top: 200px;
  }
}
