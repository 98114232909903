* {
  box-sizing: border-box;
}

.card-projects {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

img-projects-main {
  border-radius: 5px 5px 0 0;
}

.column-projects {
  float: left;
  width: 33%;
  padding: 10px 33px;
}
.column-projects h4 {
  padding: 0 10px;
}

.row-projects:after {
  content: "";
  display: table;
  clear: both;
}

.sub-text-projects {
  font-size: 1.3rem;
}

.row-projects {
  margin: 0 -5px;
}

.row-projects h3 {
  padding: 0 10px;
}
.row-projects p {
  padding: 5px 10px;
}
.row-projects h2 {
  text-align: center;
}
.row-projects h1 {
  text-align: center;
}

.sub-text-projects {
  text-align: center;
}

.grid-container-projects {
  align-content: center;

  position: relative;
  padding-bottom: 100px;
}

.nav-button-projects {
  font-weight: 500;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  background-color: #6366f1;
  /* transition: background-color 0.3s; */
  color: #ffffff;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);  */
  border-radius: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem; /* Adjust the margin as needed */
  margin-top: 0.5rem;
  /* transition-duration: 0.3s; */
  width: 10rem;
  height: 2.5rem;
}

.button-container-projects {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.link-projects {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 1440px) {
  .column-projects {
    width: 100%;
    display: block;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
  }
  .column-projects h4 {
    font-size: 32px;
  }
  .column-projects p {
    font-size: 18px;
  }
  .grid-container-projects {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .column-projects {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
  }
  .column-projects h4 {
    font-size: 18px;
  }
  .column-projects p {
    font-size: 16px;
  }
}
